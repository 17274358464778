.experience {
  text-align: left;
}

h3 {
  margin: 0px;
  padding: 0px;
  font-size: 0.5em;
}

h2 {
  font-size: 0.4em;
  font-weight: 300;
}

h5 {
  font-size: 0.4em;
  font-weight: 200;
}

.flex {
  display: flex;
  justify-content: space-between;
}
