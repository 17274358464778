:root {
  --primary-color: #e2856e;
  --secondary-color: #f4a261;
}

html {
  background-color: rgb(22, 24, 30);
}

.information p {
  font-size: 0.5em;
}

.main {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.name {
  position: relative;
}

.name::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.name:hover::after {
  transform: scaleX(1);
}

.navCons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.1s ease-in-out;
  font-size: calc(5px + 2vmin);
}

a:hover {
  color: var(--secondary-color);
}

.scrollText {
  font-size: 1.5rem;
  padding: 0px;
  margin: 0px;
}

.information {
  padding: 50px 0px;
  font-size: calc(20px + 2vmin);
  color: white;
  width: 50%;
  margin: auto;
}

@media (max-width: 1200px) {
  .header {
    font-size: calc(16px + 2vmin);
  }

  .information {
    width: 80%;
  }

  .experienceContainer {
    gap: 20px;
  }

  .projectContainer {
    gap: 20px;
  }
}

.experienceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;
}

.projectContainer {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}
