:root {
  --primary-color: #e2856e;
  --secondary-color: #f4a261;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--primary-color);
  margin-top: 20px;
  padding: 1em;
  border-radius: 3px;
  font-size: calc(5px + 2vmin);
  color: white;
  width: 40%;
  transition: border-color 0.3s ease;
}

.container:hover {
  border: 3px solid var(--secondary-color);
  animation: pulse 1s forwards;
  transition: border-color 0.3s ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.01);
  }
}

.container h1 {
  font-size: 1rem;
  margin-bottom: 0.5em;
}

.container h2 {
  font-size: 0.8rem;
  margin-bottom: 0.5em;
}

@media (max-width: 800px) {
  .container {
    width: 100%;
  }
}
